<template>
  <div class="container">

    <template v-if="News_skeleton"> 
      <v-layout row>
        <v-flex lg11 mx-6 my-8>
          <v-skeleton-loader v-bind="attrs" type="text@1" height="10" max-width="150px" style="top:10px" ></v-skeleton-loader>
        </v-flex>
      </v-layout>
      <v-row justify="center">
          <v-col class="mb-4" cols="12"  md="6" lg="3" > <v-skeleton-loader class="mr-3" v-bind="attrs" type="image"></v-skeleton-loader></v-col>
          
          <v-col class="mb-4" cols="12"  md="8" lg="8" >
            <v-skeleton-loader v-bind="attrs" type="heading"  style="top:10px" ></v-skeleton-loader>
            <v-skeleton-loader v-bind="attrs" type="text@1" height="10" max-width="250" style="top:10px" ></v-skeleton-loader>
            <v-skeleton-loader v-bind="attrs" type="text@1" height="10" max-width="150" style="top:10px" ></v-skeleton-loader>
            <v-skeleton-loader v-bind="attrs" type="text@1" height="10" max-width="150" style="top:10px" ></v-skeleton-loader>
            <v-skeleton-loader v-bind="attrs" type="text@1" height="10" max-width="150" style="top:10px" ></v-skeleton-loader>
          </v-col>
      </v-row>
      <v-row justify="center">
        <v-col class="mb-4" cols="12"  md="6" lg="3" ><v-skeleton-loader v-bind="attrs" type="image"></v-skeleton-loader></v-col>
        <v-col class="mb-4" cols="12"  md="6" lg="3" ><v-skeleton-loader v-bind="attrs" type="image"></v-skeleton-loader></v-col>
        <v-col class="mb-4" cols="12"  md="6" lg="3" ><v-skeleton-loader v-bind="attrs" type="image"></v-skeleton-loader></v-col>
        <v-col class="mb-4" cols="12"  md="6" lg="3" ><v-skeleton-loader v-bind="attrs" type="image"></v-skeleton-loader></v-col>
      </v-row>
    </template>


    <template v-else>

      <div class="title"  style=" display:flex">
        <span><img :src="$vuetify.theme.dark ? '/assets/img/z-mohammed_dark.svg' : '/assets/img/z-mohammed.svg'" /></span>
        <p class="tl" style="font-size: 24px; padding: 0 15px;">{{ $t("Picture_Reports") }}</p>
      </div>
      <hr>
      <div class="header mt-6">
        <div class="main-image-container">
          <img data-fancybox="gallery" v-if="Image" :src="'https://alkafeel.net/news/images/main/'+Image" class="main-image" alt="main photo" />
          <div class="dashed-line" v-if="mainimgs.length > 0"></div>
        </div>
        <div class="news-container">
          <h1 v-html="title" class="news-title tl"></h1>
          <div class="details">
            <div class="division d-flex" v-if="cat && $i18n.locale=='ar'">
              <span><img src="/assets/img/news_cat.svg"></span>
                <router-link :to="'/newscat/' + cat_req + '?&lang=' +$i18n.locale" class="tl" >{{ cat }}</router-link>
            </div>

            <div class="date">
                <span><img :src="$vuetify.theme.dark ? '/assets/img/date_big_white.svg' : '/assets/img/date_big_dark.svg'" /></span>
                <p class="tl d-none d-sm-flex">{{ date }}</p>
                <p class="tl d-flex d-sm-none" >{{ main_date|FormatDate }}</p>
            </div>

            <div class="views">
                <span><img src="/assets/img/view.svg"></span>
                <span class="tl">{{ hits }}</span>
            </div>

            <div class="social">
                <!-- <div :class="'share_icon ' + $store.state.direction">
                    <img :src="$vuetify.theme.dark ? '/assets/img/news/share_white.svg' : '/assets/img/news/share_dark.svg'" />
                </div> -->
                <a :href="'https://telegram.me/share/?url=https://alkafeel.net/news/?id=' + id" target="_blank" class="telegram">
                    <img width="24px" :src="$vuetify.theme.dark ? '/assets/img/news/Telegram_white.svg' : '/assets/img/news/Telegram_dark.svg'" />
                </a>
                <a :href="'https://twitter.com/intent/tweet?text=https://alkafeel.net/news/?id=' + id" target="_blank" class="twitter">
                    <img width="24px" :src="$vuetify.theme.dark ? '/assets/img/news/twitter_white.svg' : '/assets/img/news/twitter_dark.svg'" />
                </a>
                <a :href="'https://www.facebook.com/sharer/sharer.php?u=https://alkafeel.net/news/?id=' + id" target="_blank" class="facebook">
                    <img width="24px" :src="$vuetify.theme.dark ? '/assets/img/news/Facebook_white.svg' : '/assets/img/news/Facebook_dark.svg'" />
                </a>
                <a style="background-color: transparent;" @click="downloadImages" class="facebook border-right"><img  class="mx-2" width="24px" :src="$vuetify.theme.dark ? '/assets/img/news/download_dark.svg' : '/assets/img/news/download_white.svg'" /></a>
                
                <p :class="'share_text mx-2 mb-1 tl ' + $store.state.direction">{{$t("share_subject")}}</p>
                
                
            </div>
          </div>
        </div>
      </div>
                  
      <div class="image-gallery mb-6" >
        <template v-for="i in mainimgs" >
          <a data-fancybox="gallery" :data-caption="i.title"  class="sub-image" :href="'https://alkafeel.net/news/images/attach/' + i.news_id + '-' + i.img + '.' + i.ext">
            <img class="attach_img" :src="'https://alkafeel.net/news/images/attach/resized/' + i.news_id + '-' + i.img + '.' + i.ext"  :alt="i.title" />
          </a>
        </template>
      </div>

      <template v-if="relatedNews.length != 0">
        <div class="title"  style=" display:flex" >
          <span><img :src="$vuetify.theme.dark ? '/assets/img/z-mohammed_dark.svg' : '/assets/img/z-mohammed.svg'" /></span>
          <p class="tl" style="font-size: 24px; padding: 0 15px;">{{ $t("related_news") }} </p>
        </div>
        <hr>
      </template>


      <v-row  class="d-none d-sm-none d-md-flex mt-6"> 
        <v-col v-for="rel in relatedNews"  cols="12" md="6" lg="4">
          <router-link :to="rel.is_photonews === '0' ? '/new/'+ rel.id + '?&lang=' +$i18n.locale : '/new/infograph_inner/'+ rel.id + '?&lang=' +$i18n.locale" class="item" >
            <v-img :lazy-src="'https://alkafeel.net/news/images/main/resized2/'+rel.image" :src="'https://alkafeel.net/news/images/main/370/'+rel.image" style="border-radius: 4px;"> </v-img>
          </router-link>
        </v-col>
      </v-row>

      <v-layout row>
        <div class="related2 d-md-none"   v-if="relatedNews.length != 0">
          <div class="slider">
              <swiper class="swiper dirr" ref="mySwiper" :options="swiperOptions1">
                  <swiper-slide v-for="i in relatedNews" :key="i">
                  <router-link :to="i.is_photonews === '0' ? '/new/'+ i.id + '?&lang=' +$i18n.locale : '/new/infograph_inner/'+ i.id + '?&lang=' +$i18n.locale" class="item" >
                      <div>
                          <img :src="'https://alkafeel.net/news/images/main/resized3/' + i.image" :alt="i.title1" />
                      </div>
                  </router-link>
                  </swiper-slide>
              </swiper>
          </div>
        </div>
      </v-layout>
    </template>
  </div>
</template>

<script>
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox.css";

export default {
  data() {
    return {
      News_skeleton : true,
      mainNews: [],
      social: [],
      date:'',
      id:'',
      news_id:'',
      cat_req: '',
      title : '',
      cat : '',
      hits : '',
      Image: '',
      mainPage: this.$t('index_news'),
      main_date: null,
      mainimgs: [],
      relatedNews: [],
      swiperOptions1: {
          slidesPerView: 'auto',
          spaceBetween: 22,
          freeMode: true,
          keyboard: true,
      },
      attrs: {
          class: 'mb-6',
          boilerplate: false,
          elevation: 2,
      },
    }
  },
  filters: {
      subStrmostNewsitlexx: function (string) {if (string != null) {return string.substring(0, 250) + '...';}},
      subStrlastNewsitle: function (string) {if (string != null) {return string.substring(0, 150) + '...';}},
      subStrmostNewsitle: function (string) {if (string != null) {return string.substring(0, 50) + '...';}},
      subStrimpoNewsTitle: function (string) {if (string != null) {return string.substring(0, 85) + '...';}},
      FormatDate: function (unixTime) {
          let d = new Date(unixTime*1000);
          let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
          let mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d);
          let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
          return (`${da}-${mo}-${ye}`);
      }
  },
  watch: {
      '$route' (to,from) {
          let main_id = to.params.id;
          if (main_id === 'index') {main_id = to.query.id; };
          this.mainimgs = [];
          this.imgsPage = 1;
          this.attached_imgs = 0;
          const state = { 'page_id': 1, 'user_id': 5 };
          const url = '/news/index?id='+main_id+'&lang='+this.$i18n.locale;
          // history.pushState(state, '', url);
          history.replaceState({}, "", url);
          if (this.$route.params.id === 'index') {this.news_id =  this.$route.query.id;}
          else {this.news_id =  this.$route.params.id;}
          document.title =this.$t("news");
          window.scrollTo(0, 0);
          this.getmainNews();
          // this.iniFancy();
          this.getRelated();
      },
      loader () {
          const l = this.loader
          this[l] = !this[l]
          setTimeout(() => (this[l] = false), 2000)
          this.loader = null
      },
  },
  created () {
      if (this.$route.params.id === 'index') {this.news_id =  this.$route.query.id;}
      else {this.news_id =  this.$route.params.id;}
      this.getmainNews();
      document.title =this.$t("Picture_Reports"); 
  },
  methods: {
    downloadImages() {
        const imageUrls = [];
        if (this.Image) {
            imageUrls.push(`https://alkafeel.net/news/images/main/${this.Image}`);
        }
        this.mainimgs.forEach(img => {
            const url = `https://alkafeel.net/news/images/attach/${img.news_id}-${img.img}.${img.ext}`;
            imageUrls.push(url);
        });
        this.downloadAllImages(imageUrls);
    },

    downloadAllImages(urls) {
        urls.forEach(url => {
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', ''); 
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        });
    },
    getmainNews() {
      this.$axios.get("articles/GetById/" + this.news_id, {
      })
      .then(res => {
          if (res.data.length === 0) {
                this.$router.push('/articles?lang=' + this.$i18n.locale)
          }
          else {
              this.mainNews = res.data;
              this.title = res.data[0].title1;
              this.id = res.data[0].id;
              this.cat = res.data[0].cat;
              this.Image = res.data[0].image;
              this.main_date = this.mainNews[0].time;
              this.cat_req = this.mainNews[0].cat_req;
              this.hits = this.mainNews[0].hits;
              const date = new Date(this.main_date*1000);
              this.date=new Intl.DateTimeFormat(this.$i18n.locale+'-GB', { dateStyle: 'full', timeStyle: 'long' }).format(date);
              this.getAttach(this.news_id);
              this.getRelated(res.data[0].cat_req);
              setTimeout(() => this.News_skeleton = false, 500);
              
          }
      });
    },

    getRelated (x) {
        this.$axios.get("articles/getByCat/" + x + "/6", {
        })
        .then(res => {
            this.relatedNews = res.data.data;
            setTimeout(() => this.sideNews_skeleton = false, 500);
            // console.log(this.relatedNews);
        });
    },

    getAttach () {
      this.$axios.get("articles/AttachedImages/" + this.news_id, {
      })
      .then(res => {
          this.imgsPage ++ ;
          this.mainimgs = res.data.data;
      });
    },
  },


};
</script>

<style scoped>
.border-right{
  border-right: 1px solid #ccc;
  padding: 0 6px;
}
.img {
  position: relative;
}
.swiper-slide {
  width: auto;
  max-width: 390px;
}
.related2 {
  padding: 25px 20px 20px 20px;
  width: 100%;
}
.container {
  max-width: 1200px; 
  margin: 0 auto; 
  padding: 20px;
}

.header {
  display: flex;
  align-items: center; 
  position: relative;
}

.main-image-container {
  position: relative;
}

.main-image {
  width: 100%;
  max-width: 400px; 
  height: auto; 
}

.dashed-line {
  position: absolute;
  top: 50%;
  right: -50px; 
  width: 50px; 
  border-top: 2px dashed #000; 
}

.dashed-line::before {
  content: '';
  position: absolute;
  top: 322px; 
  right: 0;
  width: 50px; 
  border-top: 2px dashed #000; 
}

.dashed-line::after {
  content: '';
  position: absolute;
  top: 100%; 
  right: 0;
  width: 2px; 
  height: calc(35vh); 
  border-right: 2px dashed #000; 
}

@media (max-width: 1024px) {
  .dashed-line::after {
    height: calc(50vh); 
  }
}

@media (min-width: 1440px) and (max-width: 1840px) {
  .dashed-line::after {
    height: calc(39vh); 
  }
 
  
}

h1 {
  /* margin-right: 20px;  */
  text-align: right; 
}

.image-gallery {
  display: flex;
  flex-wrap: wrap; 
  justify-content: center;
  margin-top: 20px;
}

.sub-image {
  width: calc(25% - 16px); 
  height: auto; 
  margin: 8px;

  transition: transform 0.3s;
}

.sub-image:hover {
  transform: scale(1.2); 
}

@media (max-width: 1024px) {
  .header {
    flex-direction: column;
    align-items: flex-start;
    text-align: right;
    margin-bottom: 20px;
  }

  h1 {
    margin-right: 0;
    margin-bottom: 10px;
    text-align: right;
    width: 100%;
  }

  .main-image-container {
    width: 100%;
    margin-bottom: 20px;
    position: relative;
    align-items: center;
    text-align: center;
    order: -1;
  }



  .dashed-line {
    display: none; 
  }
}

@media (max-width: 768px) {
  .sub-image {
    width: calc(50% - 16px); 
  }
}

@media (max-width: 480px) {
  .sub-image {
    width: calc(100% - 16px); 
  }
}
.attach_img {
  width: 100%;
}

.news-container {
        padding: 20px;
    }
    .news-title {
        font-size: 24px;
        color: #333;
    }
    .details {
        margin-top: 15px;
    }
    .date, .views {
        display: flex;
        align-items: center;
        margin-top: 10px;
    }
    .views span {
        margin-left: 5px;
    }
    .social {
        display: flex;
        align-items: center;
        margin-top: 15px;
    }
    .social a {
        margin-right: 10px;
    }

    .division a {
      text-decoration: none;
      color: #808080;
      margin: 0 8px;
    }

    .date {
      align-items: flex-start !important;
    }

    .date p {
      color: #808080;
      margin: 0 8px;

    }

    .views > span {
      color: #808080;
    }
</style>
